body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h6.MuiTypography-subtitle2, button.MuiTab-root, button.MuiButtonBase-root {font-weight: 600;}

/* Dashboard */
.dashboard-item {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
}
.dashboard-item.green {
  background-color: rgba(0, 128, 0, .2);
}
.dashboard-item.red {
  background-color: rgba(255, 0, 0, .2);
}
.dashboard-item.blue {
  background-color: rgba(0, 0, 255, .2);
}
.dashboard-item.purple {
  background-color: rgba(128, 0, 128, .2);
}
.dashboard-item .content p,
.dashboard-item .content h3 {
  margin: 0;
}
.dashboard-item .content p {
  font-size: 16px;
}
.dashboard-item .content h3 {
  font-size: 32px;
  text-align-last: left;
  color: #000
}
.dashboard-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
}
.dashboard-item.green .icon {
  background-color: green;
}
.dashboard-item.red .icon {
  background-color: red;
}
.dashboard-item.blue .icon {
  background-color: blue;
}
.dashboard-item.purple .icon {
  background-color: purple;
}

.add-percentage {
  position: relative;
}
.add-percentage::after {
  content: '/ 100';
  position: absolute;
  right: 45%;
  z-index: 1;
  top: 50%;
  color: #929292;
  pointer-events: none;
}

/* Centered Card */
.full-screen-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  min-height: 100vh;
}
.full-screen-centered.with-bg {
  background-color: #0e0e0f;
  flex-direction: column;
}

/* Photo Gallery */
.photo-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.photo-item > * {
  align-self: center; 
}

.guide-photo {
  position: relative;
  width: 120px;
  height: 120px;
  display: block;
  margin-top: 15px;
}
.guide-photo img {
  width:100%;
  height: 100%;
  object-fit: contain;
}

.guide-photo::before {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, .1);
  width: 100%;
  height: 100%;
  transition: background-color .3s;
}
.guide-photo::after {
  content: '';
  background-image: url('../../public/assets/magnifying-glass.png');
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 43px;
  opacity: 1;
  transition: opacity .3s;
}
.guide-photo:hover::before {
  background-color: transparent;
}
.guide-photo:hover::after {
  opacity: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  overflow: auto;
}
.modal-content img {
  max-width: 80px;
}

.notification {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
.notification span {
  background-color: #dedede;
  border: 1px solid gray;
  font-size: 14px;
  color: gray;
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding: 5px 10px;
  border-radius: 10px;
}
.notification.true span {
  background-color: rgb(165, 241, 165);
  border-color: green;
  color: green;
  margin-bottom: 10px;
}
.notification.true small {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  max-width: 250px;
}

.game-screen {
  width: 100vw;
  height: 100vh;
  background-color: #a3a2a2;
  min-height: 100vh;
  
  font-size: calc(10px + 2vmin);
  color: white;
  background-repeat: no-repeat;
  /* background-position: center 600px; */
  background-position: center;
  background-size: cover;
  overflow-y: hidden;
}
.game-screen.no-limit {
  overflow-y: auto;
}

.game-screen .game-board {
  width: 100%;
  height: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.game-screen .game-board.classic {
  padding-top: 60px;
}

.game-screen h3 {
  font-size: 60px;
  text-align: center;
  margin-bottom: 100px;
}

.game-screen .screen-logo {
  max-width: 500px;
  margin-bottom: 30px;
}
.game-screen h2.page-title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 36px;
  margin: 0;
  margin-bottom: 30px;
}

.game-board {
  position: relative;
}

.game-control.with-button {
  background-image: none;
}

.table-container {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
}

.game-board table {
  border-spacing: 0;
  border: 2px solid #000;
  margin-top: 60px;
  width: 60%;
  border-radius: 10px;
  overflow: hidden;
  table-layout: fixed;
  font-size: 24px;
  font-weight: 500;
}

.game-board table tr {
  border-bottom: 2px solid #000;
}

.game-board table td {
  padding: 10px 20px;
  border-bottom: 2px solid #000;
}

.game-board table tr td:first-child {
  border-right: 2px solid #000;
}

.game-board table tr td:last-child {
  border-right: none;
}

.game-board table tr:last-child td {
  border-bottom: none;
}

.game-board table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

.game-board table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.game-board .game-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  min-width: 440px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.game-board h3.temporary-name {
  color: #000;
  font-size: 40px;
  margin: 0;
}
.game-board h3.draw-complete {
  color: #000;
  font-size: 36px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}


.game-screen .start-button-classic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  border: 6px solid #aa2525;
  background-color: #fff;
  color: #000;
  font-size: 18px;
  transition: color .5s, border-color .5s;
}

.platforms-container {
  width: 80%;
  margin: 0 auto;
  position: absolute;
  bottom: 15%;
}

.platforms-container .platforms {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 80px 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.platform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 80px;
}

.platform-content {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 100%;
  width: 230px;
  position: relative;
  padding-bottom: 60px;
}

.platforms-container .platforms img {
  position: relative;
  bottom: -6px;
}

.platform img {
  max-width: 100px;
  height: auto;
}

.platform .winner-name {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1rem;
  position: absolute;
  bottom: 35px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  /* transform: skew(15deg); */
}

.platform .prize-value {
  margin-top: 5px;
  font-size: 0.9rem;
  color: #555;
  position: absolute;
  bottom: -15px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.three-level-platform {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.three-level-platform .platforms {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 100%;
  height: 500px;
  width: 800px;
  position: relative;
}
.three-level-platform .platforms .single {
  text-align: center;
}
.three-level-platform .platforms .single .winner-name {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  position: absolute;
  bottom: 35px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.three-level-platform .platforms .third {
  position: absolute;
  right: 9%;
  bottom: 30px;
}
.three-level-platform .platforms .second {
  position: absolute;
  left: 23%;
  bottom: 16%;
  transform: translateX(-50%)
}
.three-level-platform .platforms .first {
  position: absolute;
  left: 50%;
  top: 6%;
  transform: translateX(-50%)
}

.platforms-container.decorated {
  width: 80%;
  bottom: 12%;
}
.platforms-container.decorated .platform {
  margin-bottom: 60px;
}
.platforms-container.decorated .platform-content {
  width: 100%;
  height: 200px;
}
.platforms-container.decorated .platform-content img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
}
.decorated .platform .winner-name {
  bottom: 42px;
}
.decorated .platform-content {
  padding-bottom: 50px;
}
.decorated .platform .prize-value {
  bottom: 0;
}


.game-modal .MuiBox-root {
  border-radius: 8px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  min-height: 400px;
}

.game-modal .MuiBox-root p {
  margin-bottom: 30px;
  font-size: 20px;
}
.game-modal .MuiBox-root h2 {
  font-size: 60px;
}

.game-modal .MuiBox-root input {
  width: 100%;
}
.game-modal form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
}
.game-modal form .MuiInputBase-root {
  margin-bottom: 15px;
}

.gallery-modal .modal-content {
  width: 70%;
  overflow-y:scroll;
  height: 60%;
  border: none;
}

.disable-clicks {
  pointer-events: none;
}

.placeholder-img {
  object-fit: contain;
  width: 60px;
  height: 60px;
}

.swal2-container {
  padding: 0;
}
.swal-container {
  border-radius: none;
}
.swal-icon {
  margin-top: 15px;
}
.swal-image {
  margin: 0 auto;
}
.swal-bg {
  overflow: visible;
  width: 100%;
  height: 100vh;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.swal-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  opacity: 0.3;
  z-index: -1;
}
.swal-prize-value {
  font-size: 75px;
}
.swal-inner-text {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 40px !important;
  font-weight: bold;
}
.swal-inner-text span {
  display: block;
}
.swal-inner-text span + span {
  font-size: 50px !important;
}
.d-none {
  display: none !important;
}

.center-inside {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  height: 100%;
}

.game-control {
  cursor: pointer;
}
.game-control h3 {
  pointer-events: none;
}